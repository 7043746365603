import classnames from 'classnames';
import React from 'react';
import {FieldError, useController, UseControllerProps} from 'react-hook-form';

import Button from '@/components/Button/Button';
import Error from '@/components/Form/FormError';

import styles from './Input.module.scss';

export type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  type: 'email' | 'number' | 'text';
  className?: string;
  unit?: string;
  error?: FieldError;
  errorKeepSpacing?: boolean;
  defaultValue?: string | number;
  button?: {
    label: string;
    onClick: () => void;
  };
};

const Input = ({
  name,
  label,
  placeholder,
  type,
  unit,
  error,
  className,
  defaultValue = '',
  button,
  control,
  rules,
  errorKeepSpacing,
}: Props & UseControllerProps): JSX.Element => {
  const {
    field: {ref, onChange, onBlur, value},
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <div
        className={classnames(
          'form__input-group',
          {'form__input-group--error': error},
          {[styles.withButton]: button}
        )}
      >
        <input
          className={styles.input}
          type={type}
          id={name}
          name={name}
          placeholder={placeholder}
          ref={ref}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        {unit && <span className={styles.unit}>{unit}</span>}
        {button && (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.buttonOuter}
              innerClassName={styles.buttonInner}
              type='button'
              color='primary'
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          </div>
        )}
      </div>
      <Error isVisible={!!error} message={error?.message} keepSpacing={errorKeepSpacing} />
    </div>
  );
};

export default Input;
