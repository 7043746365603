import React from 'react';

import LogoFull from '@/assets/LogoFull';
import FormError from '@/components/Form/FormError';
import Head from '@/components/Head/Head';

import styles from './RegistrationWrapper.module.scss';

export type TRegistrationWrapper = {
  heading?: string;
  description?: string;
  error?: string;
  success?: string;
  children?: React.ReactNode;
};

const RegistrationWrapper = ({
  heading = '',
  description = '',
  error = '',
  success = '',
  children,
}: TRegistrationWrapper): JSX.Element => {
  return (
    <section className={styles.wrapper}>
      <Head title={heading} description={description} />
      <main className={styles.container}>
        <div className={styles.logo}>
          <LogoFull className={styles.image} />
        </div>
        {heading && <h1 className={styles.heading}>{heading}</h1>}
        {description && <p className={styles.description}>{description}</p>}
        <FormError message={error} isVisible={!!error} keepSpacing isCentered />
        {!success && children && <div className={styles.children}>{children}</div>}
        {success && <p className={styles.success}>{success}</p>}
      </main>
    </section>
  );
};

export default RegistrationWrapper;
