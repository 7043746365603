import React from 'react';
import {useTranslation} from 'react-i18next';

import {translationNamespaces} from '@/common/enums';
import Button from '@/components/Button';

import styles from './RegistrationButton.module.scss';

export type TRegistrationButton = {
  isLoading?: boolean;
  label?: string;
};

const RegistrationButton = ({
  isLoading = false,
  label,
}: TRegistrationButton): JSX.Element | null => {
  const {t} = useTranslation(translationNamespaces.GLOBAL);

  if (!label) return null;

  return (
    <Button className={styles.button} color='primary' type='submit' disabled={isLoading}>
      {isLoading ? t('LOADING') : label}
    </Button>
  );
};

export default RegistrationButton;
