import classnames from 'classnames';

import styles from './FormError.module.scss';

export type TFormError = {
  isVisible?: boolean;
  isCentered?: boolean;
  keepSpacing?: boolean;
  message?: string;
};

const FormError = ({
  isVisible,
  isCentered,
  keepSpacing,
  message,
}: TFormError): JSX.Element | null => {
  if (!message && !keepSpacing) return null;

  return (
    <p
      className={classnames(
        styles.paragraph,
        {[styles.isVisible]: isVisible},
        {[styles.isCentered]: isCentered}
      )}
    >
      {message}
    </p>
  );
};

export default FormError;
