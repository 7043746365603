import {useRouter} from 'next/router';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {paths, translationNamespaces} from '@/common/enums';
import Login, {TLoginInputs} from '@/components/Registration/Login';
import {useAuth} from '@/hooks/useAuth';

const Page = (): JSX.Element | null => {
  const {user, signin} = useAuth();
  const router = useRouter();
  const {t} = useTranslation(translationNamespaces.REGISTRATION);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  async function onSubmit(data: TLoginInputs) {
    try {
      setIsLoading(true);
      setError(undefined);
      const user = await signin(data.email, data.password);
      if (!user) throw Error();
      router.push(paths.PROJECTS);
    } catch (e) {
      setIsLoading(false);
      setError(t('login.error'));
    }
  }

  useEffect(() => {
    if (user) router.push(paths.PROJECTS);
  }, [user]);

  if (user !== null) return null;

  return <Login isLoading={isLoading} onSubmit={onSubmit} error={error} />;
};

export default Page;
