import {TFunction} from 'i18next';

import {isValidEmail} from '../Form/utils';

type emailRules = {
  validate: (value: string) => string | true | undefined;
  required: string;
};

export const getEmailRules = (t: TFunction): emailRules => ({
  validate: (value: string) =>
    value !== '' ? isValidEmail(value) || `${t('errors.email.invalid')}` : undefined,
  required: `${t('errors.email.required')}`,
});
