import classnames from 'classnames';
import React, {useRef, useState} from 'react';
import {ChangeHandler, FieldError} from 'react-hook-form';

import {Check, Eye} from '@/assets/icons';

export const validator = {
  minLength: (value: string | undefined): boolean | string =>
    (value && value.length >= 8) || 'Doit contenir au moins 8 charactères',
  hasUppercase: (value: string | undefined): boolean | string =>
    (value && /(?=.*[A-Z])/.test(value)) || 'Doit contenir au moins une majuscule',
  hasDigit: (value: string | undefined): boolean | string =>
    (value && /(?=.*[0-9])/.test(value)) || 'Doit contenir au moins un chiffre',
};

export type Props = {
  name: string;
  label: string;
  placeholder?: string;
  className?: string;
  watcher?: string;
  withHints?: boolean;
  error?: FieldError;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
};

const Password = (
  {name, label, placeholder, error, className, watcher, withHints, onChange, onBlur}: Props,
  ref: React.LegacyRef<HTMLInputElement>
): JSX.Element => {
  const [reveal, setReveal] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  function handleReveal() {
    if (containerRef && containerRef.current) {
      const input = containerRef.current.querySelector('input');
      if (input) {
        setReveal((prev) => !prev);
        input.focus();
      }
    }
  }

  return (
    <div className={className} ref={containerRef}>
      <label htmlFor={name}>
        {label}
        {withHints && (
          <>
            <span
              className={classnames(`form__hint`, {
                'form__hint--valid': validator.minLength(watcher) === true,
              })}
            >
              <Check className='form__hint-icon' />
              <span className='form__hint-text'>Doit contenir au moins 8 caractères</span>
            </span>
            <span
              className={classnames(`form__hint`, {
                'form__hint--valid': validator.hasUppercase(watcher) === true,
              })}
            >
              <Check className='form__hint-icon' />
              <span className='form__hint-text'>Doit contenir au moins une majuscule</span>
            </span>
            <span
              className={classnames(`form__hint`, {
                'form__hint--valid': validator.hasDigit(watcher) === true,
              })}
            >
              <Check className='form__hint-icon' />
              <span className='form__hint-text'>Doit contenir au moins un chiffre</span>
            </span>
          </>
        )}
      </label>
      <div className={classnames('form__input-group', {'form__input-group--error': error})}>
        <input
          type={reveal ? 'text' : 'password'}
          id={name}
          name={name}
          placeholder={placeholder}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
        />
        <button
          type='button'
          onClick={handleReveal}
          aria-pressed={reveal}
          className='form__password-reveal'
        >
          <span className='form__password-reveal-icon' title='Voir le mot de passe' tabIndex={-1}>
            <Eye title='Voir le mot de passe' />
          </span>
        </button>
      </div>
      {error && <p className='form__error'>{error.message}</p>}
    </div>
  );
};

export default React.forwardRef(Password);
