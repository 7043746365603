import Link from 'next/link';
import React from 'react';
import {Control, FieldValues, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {paths, translationNamespaces} from '@/common/enums';
import Input from '@/components/Form/Input/Input';
import Password from '@/components/Form/Password';
import Button from '@/components/Registration/Button';
import RegistrationWrapper from '@/components/Registration/Wrapper';

import styles from '../Registration.module.scss';
import {getEmailRules} from '../RegistrationHelpers';

export type TLoginInputs = {
  email: string;
  password: string;
};

export type TLogin = {
  onSubmit: (data: TLoginInputs) => Promise<void>;
  isLoading?: boolean;
  error?: string;
};

const Login = ({onSubmit, isLoading = false, error}: TLogin): JSX.Element | null => {
  const {t} = useTranslation(translationNamespaces.REGISTRATION);
  const {t: tGlobal} = useTranslation(translationNamespaces.GLOBAL);
  const {control, register, handleSubmit, formState} = useForm<TLoginInputs>({mode: 'onTouched'});

  return (
    <RegistrationWrapper
      heading={t('login.heading')}
      description={t('login.description')}
      error={error}
    >
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          type='email'
          name='email'
          label={tGlobal('EMAIL')}
          placeholder={t('login.email.placeholder')}
          className={styles.input}
          control={control as Control<FieldValues>}
          rules={getEmailRules(t)}
          error={formState.errors.email}
        />
        <Password
          {...register('password', {
            required: `${t('login.password.required')}`,
          })}
          label={tGlobal('PASSWORD')}
          placeholder={t('login.password.placeholder')}
          className={styles.input}
          error={formState.errors.password}
        />

        <div className={styles.link}>
          <Link href={paths.FORGOT_PASSWORD}>
            <a className={styles.anchor}>{t('login.password.forgotten')}</a>
          </Link>
        </div>

        <Button isLoading={isLoading} label={t('login.submit')} />
      </form>
    </RegistrationWrapper>
  );
};

export default Login;
